.map {
    width: 100%;
    height: calc(100vh - 80px);
}

.svgLayer {
    width: 1150px;
    height: 1080px;
    transform-origin: top left;
}

.pathHeader {
    margin: 2rem 0 1rem 2rem;
}

.svgLayer {
    position: absolute;
    z-index: -200;
}

.currentFloor {
    border: 1px solid black;
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    right: 0;
}

.pathLength {
    margin-top: 2rem;
    position: relative;
    top: -0.45rem;
}

.pathIcons {
    margin-right: 1rem;
}